// TODO: Remove this file once backend data integration and tests are completed.
import { AvailableCapacityData, MaxCapacityMap, RouteInfoCardProps } from '@/root/shared-types'

export const capacityTestData_1: AvailableCapacityData[] = [
  { time: '7:00 am', bicyclesCount: 1, carsCount: 5 },
  { time: '1:00 pm', passengersCount: 100, bicyclesCount: 79, carsCount: 80 },
  { time: '10:00 pm', passengersCount: 0, bicyclesCount: 12, carsCount: 1 },
  { time: '11:00 pm', passengersCount: 49, bicyclesCount: 50, carsCount: 75 },
  { time: '11:30 pm', passengersCount: 88, carsCount: 99 },
]

export const maxCapacityValues_1: MaxCapacityMap = {
  maxPassengers: 100,
  maxBicycles: 100,
  maxCars: 100,
}

export const capacityTestData_2: AvailableCapacityData[] = [
  { time: '7:00 am', passengersCount: 2000, carsCount: 100 },
  { time: '1:00 pm', passengersCount: 2900, carsCount: 908 },
  { time: '10:00 pm', passengersCount: 2, carsCount: 1 },
  { time: '11:00 pm', passengersCount: 10, carsCount: 15 },
]

export const maxCapacityValues_2: MaxCapacityMap = {
  maxPassengers: 3000,
  maxCars: 1000,
}

export const capacityTestData_3: AvailableCapacityData[] = [
  { time: '7:00 am', passengersCount: 100 },
  { time: '1:00 pm', passengersCount: 799 },
  { time: '10:00 pm', passengersCount: 800 },
  {
    time: '11:00 pm',
    passengersCount: 1000,
    noHeavyTracks: true,
    dangerousGoods: true,
    cancelledSail: true,
  },
]

export const maxCapacityValues_3: MaxCapacityMap = {
  maxPassengers: 1000,
}

export const capacityTestData_4: AvailableCapacityData[] = [
  {
    time: '7:00 am',
    passengersCount: 20,
    bicyclesCount: 5,
    carsCount: 100,
    hasTrucks: true,
    dangerousGoods: true,
    noHeavyTracks: true,
    cancelledSail: true,
  },
  {
    time: '1:00 pm',
    passengersCount: 79,
    bicyclesCount: 80,
    carsCount: 35,
    hasTrucks: true,
    dangerousGoods: false,
    noHeavyTracks: true,
    cancelledSail: true,
  },
  {
    time: '10:00 pm',
    passengersCount: 1,
    bicyclesCount: 12,
    carsCount: 0,
    hasTrucks: true,
    dangerousGoods: false,
    noHeavyTracks: false,
    cancelledSail: true,
  },
  {
    time: '11:00 pm',
    passengersCount: 49,
    bicyclesCount: 50,
    carsCount: 75,
    hasTrucks: true,
    dangerousGoods: false,
    noHeavyTracks: false,
    cancelledSail: false,
  },
]

export const capacityTestData_5: AvailableCapacityData[] = [
  {
    time: '7:00 am',
    bicyclesCount: 5,
    carsCount: 100,
    hasTrucks: true,
    dangerousGoods: true,
    noHeavyTracks: true,
    cancelledSail: true,
  },
  {
    time: '1:00 pm',
    bicyclesCount: 80,
    carsCount: 35,
    hasTrucks: true,
    dangerousGoods: false,
    noHeavyTracks: true,
    cancelledSail: true,
  },
  {
    time: '10:00 pm',
    bicyclesCount: 12,
    carsCount: 0,
    hasTrucks: true,
    dangerousGoods: false,
    noHeavyTracks: false,
    cancelledSail: true,
  },
  {
    time: '11:00 pm',
    bicyclesCount: 50,
    carsCount: 75,
    hasTrucks: true,
    dangerousGoods: false,
    noHeavyTracks: false,
    cancelledSail: false,
  },
]

export const capacityTestData_6: AvailableCapacityData[] = [
  {
    time: '7:00 am',
    carsCount: 100,
    hasTrucks: true,
    dangerousGoods: true,
    noHeavyTracks: true,
    cancelledSail: true,
  },
  {
    time: '1:00 pm',
    carsCount: 35,
    hasTrucks: true,
    dangerousGoods: false,
    noHeavyTracks: true,
    cancelledSail: true,
  },
  {
    time: '10:00 pm',
    carsCount: 0,
    hasTrucks: true,
    dangerousGoods: false,
    noHeavyTracks: false,
    cancelledSail: true,
  },
  {
    time: '11:00 pm',
    carsCount: 75,
    hasTrucks: true,
    dangerousGoods: false,
    noHeavyTracks: false,
    cancelledSail: false,
  },
]

export const capacityTestData_1b: AvailableCapacityData[] = [
  {
    time: '7:00 am',
    passengersCount: 20,
    bicyclesCount: 5,
    carsCount: 100,
    dangerousGoods: true,
    noHeavyTracks: true,
    cancelledSail: false,
  },
  {
    time: '1:00 pm',
    passengersCount: 79,
    bicyclesCount: 80,
    carsCount: 35,
    dangerousGoods: false,
    noHeavyTracks: true,
    cancelledSail: false,
    closed: true,
  },
  {
    time: '10:00 pm',
    passengersCount: 1,
    bicyclesCount: 12,
    carsCount: 0,
    dangerousGoods: false,
    noHeavyTracks: false,
    cancelledSail: false,
  },
  {
    time: '11:00 pm',
    passengersCount: 49,
    bicyclesCount: 50,
    carsCount: 75,
    dangerousGoods: true,
    noHeavyTracks: true,
    cancelledSail: true,
  },
  {
    time: '11:30 pm',
    passengersCount: 49,
    bicyclesCount: 50,
    carsCount: 75,
    dangerousGoods: false,
    noHeavyTracks: false,
    cancelledSail: false,
  },
]

export const maxCapacityValues_4: MaxCapacityMap = {
  maxPassengers: 100,
  maxBicycles: 100,
  maxCars: 100,
}

export const maxCapacityValues_5: MaxCapacityMap = {
  maxBicycles: 100,
  maxCars: 100,
}

export const maxCapacityValues_6: MaxCapacityMap = {
  maxCars: 100,
}

export const routeInfo_1: RouteInfoCardProps['routeInfo'] = {
  departurePort: 'Al Dhana',
  arrivalPort: 'Dalma Island',
  shipName: 'Yameela',
  durationInMinutes: '146',
}
