import { FC, HTMLAttributes } from 'react'
import { useGetTranslations } from '@/root/shared-hooks'

export const NoMoreSailsNotice: FC<HTMLAttributes<HTMLDivElement>> = (props) => {
  const { getTranslation } = useGetTranslations()
  return (
    <article
      className="text-base-normal-lighter flex flex-col items-center px-3 text-center xs:px-5 sm:px-0"
      {...props}
    >
      <h1 className="text-large text-neutral-medium-lighter font-bold">{getTranslation('noMoreSailsTitle')}</h1>
      <p className="w-60 text-neutral-medium-lighter leading-7">{getTranslation('noMoreSailsSubtitle')}</p>
    </article>
  )
}
