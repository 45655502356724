import { FC } from 'react'
import { BottomArrowIcon, Button } from '@/root/ui/admaritime'
import { ButtonProps } from '@/root/ui/admaritime/Button/Button'
import { useGetTranslations } from '@/root/shared-hooks'

interface SelectTicketButtonProps extends ButtonProps {
  disabled: boolean
}

export const SelectTicketButton: FC<SelectTicketButtonProps> = ({ disabled }) => {
  const { getTranslation } = useGetTranslations()

  return (
    <div data-testid="go-to-next" className="flex h-16 w-full max-w-96 gap-5">
      <div data-testid="next-step-button" className="flex h-16 w-full max-w-96 gap-5">
        <Button
          variant="contained"
          className="order-first flex h-16 w-full max-w-96 items-center justify-between gap-1 lg:order-last"
          disabled={disabled}
        >
          <div data-testid="next-button-labels" className="flex items-center justify-between gap-4">
            <p className="-ms-2 text-sm font-normal lowercase opacity-50">{getTranslation('continueLabel')}</p>
            <div className="flex items-center gap-6">
              <p className="xxs:text-2xl text-lg uppercase">{getTranslation('selectTicketLabel')}</p>
              <div className="-me-2 ltr:-rotate-90 rtl:rotate-90">
                <BottomArrowIcon color="white" width={16} />
              </div>
            </div>
          </div>
        </Button>
      </div>
    </div>
  )
}
