import { FC, HTMLAttributes } from 'react'
import cn from 'classnames'
import { colorThresholds } from '@/root/shared-consts'

interface ProgressBarProps extends HTMLAttributes<HTMLProgressElement> {
  value: number
  max: number
  hasTruck?: boolean
}

const { GREEN_PERCENT, YELLOW_PERCENT, MIN_PERCENT } = colorThresholds

export const ProgressBar: FC<ProgressBarProps> = ({ value = 0, max = 100, hasTruck = false, className, ...rest }) => {
  const percent = Math.round((value / max) * 100)
  const minThreshold = Math.round((MIN_PERCENT * max) / 100)

  const isGreen = percent >= GREEN_PERCENT
  const isYellow = percent >= YELLOW_PERCENT && percent < GREEN_PERCENT
  const isRed = percent < YELLOW_PERCENT

  return (
    <progress
      className={cn(
        'h-1.5 bg-adm-gray-300 rounded-sm [&::-moz-progress-bar]:rounded-sm [&::-webkit-progress-bar]:rounded-sm [&::-webkit-progress-bar]:bg-adm-gray-300 [&::-webkit-progress-value]:rounded-sm',
        hasTruck ? 'progress-bar-large' : 'progress-bar-small',
        {
          '[&::-moz-progress-bar]:bg-success-medium-lighter [&::-webkit-progress-value]:bg-success-medium-lighter':
            isGreen,
          '[&::-moz-progress-bar]:bg-adm-yellow-400 [&::-webkit-progress-value]:bg-adm-yellow-400': isYellow,
          '[&::-moz-progress-bar]:bg-adm-red-400 [&::-webkit-progress-value]:bg-adm-red-400': isRed,
        },
        className
      )}
      value={percent > MIN_PERCENT ? value : minThreshold}
      max={max}
      {...rest}
    />
  )
}
