import React, { FC, useState, HTMLAttributes } from 'react'
import { CalendarIcon } from '@/root/ui/shared/icons/CalendarIcon'
import { useCustomRouter, useGetTranslations } from '@/root/shared-hooks'
import DayPicker from '@/root/components/shared/DayPicker/DayPicker'
import { timeFormat } from '@/root/utils/timeFormat'
import { DayPickerProps } from '@/root/shared-types'

interface ChangeDateButtonProps extends DayPickerProps, HTMLAttributes<HTMLDivElement> {
  time: string
}

export const ChangeDateButton: FC<ChangeDateButtonProps> = ({
  selectedDate,
  onDayClick,
  isDayDisabled,
  time,
  ...rest
}) => {
  const { getTranslation } = useGetTranslations()
  const { locale } = useCustomRouter()
  const formattedDate = selectedDate && timeFormat(selectedDate, locale, 'en-GB')

  const [isOpened, setIsOpened] = useState(false)

  const toggleDayPicker = () => {
    setIsOpened((prev) => !prev)
  }

  const handleDayClick = (date: Date) => {
    if (onDayClick) {
      onDayClick(date)
    }
    setIsOpened(false)
  }

  return (
    <div className="relative" {...rest}>
      <button
        className="flex w-full justify-between rounded-md bg-white p-4 font-bold shadow-sm"
        onClick={toggleDayPicker}
        data-testid="departure-form"
      >
        <div className="text-primary-base flex flex-col justify-between text-start text-lg uppercase">
          <p>{formattedDate}</p>
          <p>{time}</p>
        </div>
        <div className="text-secondary flex w-16 flex-col items-center justify-between gap-1.5 text-xs">
          <CalendarIcon className="ml-2" />
          <p>{getTranslation('changeDateLabel')}</p>
        </div>
      </button>
      {isOpened && (
        <DayPicker
          className="absolute z-20 -mt-3 w-full rounded-lg bg-white"
          selectedDate={selectedDate}
          onDayClick={handleDayClick}
          isDayDisabled={isDayDisabled}
        />
      )}
    </div>
  )
}

export default ChangeDateButton
