import { SelectDateTimeAction, SelectDateTimeState } from './types'
import { SET_DATE, SET_TIME, SWAP_PORTS, SET_PORTS, SET_HIDE_ROUND_TRIP_BUTTON, SET_AVAILABLE_DATES } from './actions'
import { neverError } from '@/root/shared-providers/BookingCart/reducer'

export const selectDateTimeReducer = (
  state: SelectDateTimeState,
  action: SelectDateTimeAction
): SelectDateTimeState => {
  switch (action.type) {
    case SET_DATE: {
      const { direction, date } = action.payload
      const isDirectionTo = direction === 'to'
      const dirKey = isDirectionTo ? 'there' : 'back'
      const backDate = state.sail.back.date

      const updatedSail =
        isDirectionTo && backDate && date > backDate
          ? {
              there: { ...state.sail.there, date },
              back: { ...state.sail.back, date },
            }
          : {
              ...state.sail,
              [dirKey]: { ...state.sail[dirKey], date },
            }
      return {
        ...state,
        sail: updatedSail,
      }
    }
    case SET_TIME: {
      const { direction, time } = action.payload
      const dirKey = direction === 'to' ? 'there' : 'back'
      return {
        ...state,
        sail: {
          ...state.sail,
          [dirKey]: { ...state.sail[dirKey], time },
        },
      }
    }
    case SET_AVAILABLE_DATES: {
      const { direction, availableDates } = action.payload
      const dirKey = direction === 'to' ? 'there' : 'back'
      return {
        ...state,
        sail: {
          ...state.sail,
          [dirKey]: { ...state.sail[dirKey], availableDates },
        },
      }
    }

    case SWAP_PORTS: {
      const { there, back } = state.sail
      return {
        ...state,
        sail: {
          ...state.sail,
          there: {
            ...there,
            port: back.port,
            availableDates: back.availableDates,
            inventory: back.inventory,
            time: back.inventory[0].time,
          },
          back: {
            ...back,
            port: there.port,
            availableDates: there.availableDates,
            inventory: there.inventory,
            time: there.inventory[0].time,
          },
        },
      }
    }
    case SET_PORTS: {
      const { from, to } = action.payload
      return {
        ...state,
        sail: {
          ...state.sail,
          there: { ...state.sail.there, port: from },
          back: { ...state.sail.back, port: to },
        },
      }
    }
    case SET_HIDE_ROUND_TRIP_BUTTON: {
      const hide = typeof action.payload === 'function' ? action.payload(state.hideRoundTripButton) : action.payload
      return {
        ...state,
        hideRoundTripButton: hide,
      }
    }
    default:
      neverError('Irrelevant type', action)
      return { ...state }
  }
}
