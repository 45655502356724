import { FC, HTMLAttributes, useMemo } from 'react'
import { useGetTranslations } from '@/root/shared-hooks'
import { CapacityTableHead, CapacityTableRow, CapacityTableTitle } from '@/root/modules/shared/SelectDateTimePage'
import { AvailableCapacityData, MaxCapacityMap, CapacityTableColumns } from '@/root/shared-types'
import { DangerGoodsIcon } from '@/root/ui/shared/icons/DangerGoodsIcon'
import { NoTrucksIcon } from '@/root/ui/shared/icons/NoTrucksIcon'
import { AnchorIcon } from '@/root/ui/shared/icons/AnchorIcon'

interface AvailableCapacityTableProps extends HTMLAttributes<HTMLDivElement> {
  data: AvailableCapacityData[]
  maxValues: MaxCapacityMap
  selectedTime: string
  setSelectedTime: (time: string) => void
}

export const AvailableCapacityTable: FC<AvailableCapacityTableProps> = ({
  data,
  maxValues,
  selectedTime,
  setSelectedTime,
}) => {
  const { getTranslation } = useGetTranslations()

  const showColumns: CapacityTableColumns = useMemo(
    () => ({
      showPassengers: data.some((item) => 'passengersCount' in item),
      showBicycles: data.some((item) => 'bicyclesCount' in item),
      showCars: data.some((item) => 'carsCount' in item),
      showTrucks: data.some((item) => item.hasTrucks === true),
    }),
    [data]
  )

  const { showCars } = showColumns

  const showDangerGoods = data.some((item) => item.dangerousGoods === true)
  const showNoTrucks = data.some((item) => item.noHeavyTracks === true)
  const showAnchor = data.some((item) => item.cancelledSail === true)

  const warningIconsInRow = useMemo(() => {
    return Math.max(
      ...data.map((item) => [item.dangerousGoods, item.noHeavyTracks, item.cancelledSail].filter(Boolean).length)
    )
  }, [data])

  const showZeroWarning = data.some(
    (item) => item.passengersCount === 0 || item.bicyclesCount === 0 || item.carsCount === 0
  )

  return (
    <div
      data-testid="sail-select-table"
      className="flex w-full flex-col items-center gap-2.5 text-sm font-bold uppercase"
    >
      <CapacityTableTitle title={getTranslation('availableCapacity')} warningIconsInRow={warningIconsInRow} />

      <div className="flex w-full flex-col items-center gap-2.5">
        <CapacityTableHead showColumns={showColumns} warningIconsCountInRow={warningIconsInRow} />
        {data.map((item) => (
          <CapacityTableRow
            key={item.time}
            isSelected={selectedTime === item.time}
            data={item}
            maxValues={maxValues}
            showColumns={showColumns}
            onChange={() => setSelectedTime(item.time)}
            warningIconsCountInRow={warningIconsInRow}
          />
        ))}
      </div>

      {showDangerGoods && (
        <div className="text-hazard flex gap-5 self-start ps-2.5 pt-5 ">
          <div className="shrink-0">
            <DangerGoodsIcon width={25} data-testid="danger-goods-icon" />
          </div>
          <p>{getTranslation('dangerousGoods')}</p>
        </div>
      )}
      {showNoTrucks && (
        <div className="flex gap-5 self-start ps-2.5 pt-5 font-normal">
          <div className="shrink-0">
            <NoTrucksIcon width={25} />
          </div>
          <p>{getTranslation('noHeavyTracks')}</p>
        </div>
      )}
      {showAnchor && (
        <div className="text-error-dark flex gap-5 self-start ps-2.5 pt-5">
          <div className="shrink-0">
            <AnchorIcon width={23} />
          </div>
          <p>{getTranslation('noteAboutCancelled')}</p>
        </div>
      )}

      {showCars && (
        <p className="text-primary-base px-2.5 pt-5 text-justify font-bold normal-case">
          {getTranslation('clueAdmVehicleAndPassenger')}
        </p>
      )}

      {showZeroWarning && (
        <div className="self-start ps-2.5 pt-5 text-adm-red-400">
          <span className="text-large">* </span>
          <span>{getTranslation('outOfStockPart1')}</span>
        </div>
      )}
    </div>
  )
}
