import { FC } from 'react'
import dynamic from 'next/dynamic'
import { BookingProgressBar, BookingProgressBarContainer } from '@/root/modules/shared'
import { parseSailPackages, SelectDateTimeContent } from '@/root/modules/shared/SelectDateTimePage'
import { bookingSteps } from '@/root/shared-consts'
import { useErrorBanner } from '@/root/shared-hooks/useErrorBanner/useErrorBanner'
import { useCustomRouter, useGetSailPackage, useGetSailPackageById, useGetTranslations } from '@/root/shared-hooks'
import { useConfig } from '@/root/shared-hooks'

const { SELECT_TICKETS } = bookingSteps

interface BannerProps {
  variant: string
  title?: string
  body?: React.ReactNode
  className?: string
}

export const SelectDateTimePage: FC = () => {
  const { getTranslation } = useGetTranslations()
  const { errorBody } = useErrorBanner()
  const { theme } = useConfig()
  const { sailPackage:packageFromContext } = useGetSailPackage()
  const { locale, query } = useCustomRouter()
  const sailParams = parseSailPackages(query)
  const packageById = useGetSailPackageById({ locale, code: sailParams[0]?.code })

  const routeBaseInfo = packageFromContext ?? (packageById || undefined)

  const ThemedBanner = dynamic<BannerProps>(
    () => import(`@/root/ui/${theme === 'admaritime' ? 'admaritime' : 'bluewave'}/Banner/Banner`)
  )

  const bannerVariant = theme === 'admaritime' ? 'warning' : 'error'

  return (
    <div className="mx-auto my-10 flex max-w-screen-xl flex-col bg-white shadow-complex">
      <BookingProgressBarContainer>
        <BookingProgressBar maxReachedStep={SELECT_TICKETS} />
      </BookingProgressBarContainer>
      {(errorBody || true) && (
        <ThemedBanner
          variant={bannerVariant}
          className="rounded-none border-none [&>h2]:uppercase"
          title={getTranslation('bookingProcessErrorTitle')}
          body={getTranslation('bookingProcessError')}
        />
      )}
      <SelectDateTimeContent routeBaseInfo={routeBaseInfo} sailParams={sailParams} />
    </div>
  )
}
