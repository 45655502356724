import {
  SET_AVAILABLE_DATES,
  SET_DATE,
  SET_HIDE_ROUND_TRIP_BUTTON,
  SET_PORTS,
  SET_TIME,
  SWAP_PORTS,
} from '@/root/modules/shared/SelectDateTimePage/state/actions'
import { FC, SetStateAction, useEffect, useReducer } from 'react'
import { GoBackButton, RouteInfoSection, SelectTicketButton } from '@/root/modules/shared/SelectDateTimePage'
import { useCustomRouter, useGetAvailableDates } from '@/root/shared-hooks'
import {
  capacityTestData_1b as capacityTestData,
  capacityTestData_1,
} from '@/root/modules/shared/SelectDateTimePage/data/testData'
import { maxCapacityValues_1 as maxCapacityValues } from '@/root/modules/shared/SelectDateTimePage/data/testData'
import { routeInfo_1 as routeInfo } from '@/root/modules/shared/SelectDateTimePage/data/testData'
import { checkIsDayDisabled } from '@/root/shared-helpers'
import { Direction, SailPackage } from '@/root/shared-types'
import { SailParam } from '@/root/modules/shared/SelectDateTimePage/helpers/parseSailPackages'
import { SelectDateTimeState } from '@/root/modules/shared/SelectDateTimePage/state/types'
import { selectDateTimeReducer } from '@/root/modules/shared/SelectDateTimePage/state/reducer'

interface SelectDateTimeContentProps {
  sailParams: Array<SailParam>
  routeBaseInfo?: SailPackage
}

export const SelectDateTimeContent: FC<SelectDateTimeContentProps> = ({ routeBaseInfo, sailParams }) => {
  const fallbackRouteId = routeBaseInfo?.backwardSailPackage

  const { locale } = useCustomRouter()

  const [routeIdTo, routeIdFrom] = [sailParams[0]?.code, sailParams[1]?.code || fallbackRouteId]
  const [dateTo, dateFrom] = [sailParams[0]?.sailDates[0], sailParams[1]?.sailDates[0]]

  const availableDatesTo = useGetAvailableDates({ route: String(routeIdTo) })
  const availableDatesFrom = useGetAvailableDates({ route: String(routeIdFrom) })

  const today = new Date()
  const toDate = dateTo ? new Date(String(dateTo)) : today
  const fromDate = dateFrom ? new Date(String(dateFrom)) : toDate

  const initialState: SelectDateTimeState = {
    sail: {
      there: {
        date: toDate,
        time: capacityTestData[0].time,
        availableDates: availableDatesTo,
        port: '',
        inventory: capacityTestData,
      },
      back: {
        date: fromDate,
        time: capacityTestData_1[0].time,
        availableDates: availableDatesFrom,
        port: '',
        inventory: capacityTestData_1,
      },
    },
    hideRoundTripButton: Boolean(dateFrom),
  }

  const [state, dispatch] = useReducer(selectDateTimeReducer, initialState)
  const { there, back } = state.sail

  useEffect(() => {
    if (routeBaseInfo?.titles) {
      const routeName = routeBaseInfo.titles[locale] || ''
      const [from = '', to = ''] = routeName.split(' - ').map((part: string) => part.trim())

      dispatch({ type: SET_PORTS, payload: { from, to } })
    }
  }, [routeBaseInfo?.titles, locale])

  useEffect(() => {
    if (availableDatesTo.length > 0 && there.availableDates.length === 0) {
      dispatch({ type: SET_AVAILABLE_DATES, payload: { direction: 'to', availableDates: availableDatesTo } })
    }
    if (availableDatesFrom.length > 0 && back.availableDates.length === 0) {
      dispatch({ type: SET_AVAILABLE_DATES, payload: { direction: 'from', availableDates: availableDatesFrom } })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableDatesTo.length, availableDatesFrom.length, routeIdTo, routeIdFrom])

  const swapPorts = () => {
    dispatch({ type: SWAP_PORTS })
  }

  const handleDateChange = (direction: Direction) => (date: Date) => {
    dispatch({ type: SET_DATE, payload: { direction, date } })
  }

  const handleTimeChange = (direction: Direction) => (time: string) => {
    dispatch({ type: SET_TIME, payload: { direction, time } })
  }

  const setHideRoundTripButton = (hide: SetStateAction<boolean>) => {
    dispatch({ type: SET_HIDE_ROUND_TRIP_BUTTON, payload: hide })
  }

  return (
    <div data-testid="schedule-wrapper">
      <RouteInfoSection
        capacityData={there.inventory}
        maxCapacityData={maxCapacityValues}
        routeInfo={{
          ...routeInfo,
          departurePort: there.port,
          arrivalPort: back.port,
        }}
        swapPorts={swapPorts}
        hideRoundtripButton={state.hideRoundTripButton}
        setHideRoundTripButton={setHideRoundTripButton}
        selectedDate={there.date}
        onDayClick={handleDateChange('to')}
        isDayDisabled={checkIsDayDisabled({ availableDates: there.availableDates })}
        time={there.time || ''}
        setSelectedTime={handleTimeChange('to')}
      />

      {state.hideRoundTripButton && (
        <RouteInfoSection
          dataTestId="departure-back-card"
          capacityData={back.inventory}
          maxCapacityData={maxCapacityValues}
          routeInfo={{
            ...routeInfo,
            departurePort: back.port,
            arrivalPort: there.port,
          }}
          swapPorts={swapPorts}
          setHideRoundTripButton={setHideRoundTripButton}
          showDivider
          showCancelButton
          hideRoundtripButton
          selectedDate={back.date || there.date}
          onDayClick={handleDateChange('from')}
          isDayDisabled={checkIsDayDisabled({
            availableDates: back.availableDates,
            disableBeforeDate: there.date,
          })}
          time={back.time || ''}
          setSelectedTime={handleTimeChange('from')}
        />
      )}

      <div
        data-testid="bottom-slider"
        className="mb-8 flex flex-col items-center gap-8 p-4 lg:mb-0 lg:flex-row lg:justify-between lg:p-7"
      >
        <GoBackButton />
        <SelectTicketButton disabled={capacityTestData.length === 0} />
      </div>
    </div>
  )
}
