import { FC } from 'react'
import { AvailableCapacityData, DayPickerProps, MaxCapacityMap, RouteInfoCardProps } from '@/root/shared-types'
import {
  AddReturnRouteButton,
  AvailableCapacityTable,
  ChangeDateButton,
  NoMoreSailsNotice,
  RouteInfoCard,
} from '@/root/modules/shared/SelectDateTimePage'
import { Divider } from '@/root/ui/shared/Divider/Divider'

interface RouteInfoSectionProps extends RouteInfoCardProps, DayPickerProps {
  time: string
  setSelectedTime: (time: string) => void
  capacityData?: AvailableCapacityData[]
  maxCapacityData?: MaxCapacityMap
  showDivider?: boolean
  hideRoundtripButton?: boolean
  dataTestId?: string
}

const defaultMaxCapacityData: MaxCapacityMap = {
  maxPassengers: 0,
  maxBicycles: 0,
  maxCars: 0,
}

export const RouteInfoSection: FC<RouteInfoSectionProps & { dataTestId?: string }> = ({
  routeInfo,
  hideRoundtripButton = false,
  showDivider = false,
  showCancelButton = false,
  capacityData = [],
  maxCapacityData = defaultMaxCapacityData,
  setHideRoundTripButton,
  swapPorts,
  selectedDate,
  onDayClick,
  isDayDisabled,
  time,
  setSelectedTime,
  dataTestId = 'departure-card',
}) => {
  return (
    <>
      {showDivider && (
        <div className="relative mx-5 my-3.5 sm:mx-10">
          <Divider variant={'light'} />
        </div>
      )}

      <section data-testid={dataTestId} className="flex flex-col gap-5 px-3 pb-5 pt-12 xs:px-5 lg:flex-row">
        <div className="flex flex-col gap-5 px-3 xs:px-5 lg:min-w-96 xl:min-w-400">
          <ChangeDateButton
            selectedDate={selectedDate}
            onDayClick={onDayClick}
            isDayDisabled={isDayDisabled}
            time={time}
          />
          <RouteInfoCard
            routeInfo={routeInfo}
            showCancelButton={showCancelButton}
            setHideRoundTripButton={setHideRoundTripButton}
            swapPorts={swapPorts}
          />
          {!hideRoundtripButton && <AddReturnRouteButton setHideRoundTripButton={setHideRoundTripButton} />}
        </div>

        <div className="grow sm:px-5">
          {!capacityData.length && <NoMoreSailsNotice />}
          {Boolean(capacityData.length) && (
            <AvailableCapacityTable
              data={capacityData}
              maxValues={maxCapacityData}
              setSelectedTime={setSelectedTime}
              selectedTime={time}
            />
          )}
        </div>
      </section>
    </>
  )
}
