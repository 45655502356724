import { FC, HTMLAttributes } from 'react'
import cn from 'classnames'

interface CapacityTableTitleProps extends HTMLAttributes<HTMLHeadingElement> {
  title: string
  warningIconsInRow: number
}

export const CapacityTableTitle: FC<CapacityTableTitleProps> = ({ title, warningIconsInRow, ...props }) => {
  return (
    <h2
      className={cn('text-neutral-medium-lighter font-normal normal-case', {
        'ms-14 xxs:ms-12 sm:ms-16 md:-ms-1 lg:ms-16 xl:-ms-1': warningIconsInRow === 0,
        'ms-20 sm:ms-24 md:ms-6 lg:ms-24 xl:ms-6': warningIconsInRow === 1,
        'ms-28 sm:ms-32 md:ms-12 lg:ms-32 xl:ms-12': warningIconsInRow === 2,
        'ms-32 sm:ms-36 md:ms-20 lg:ms-36 xl:ms-20': warningIconsInRow === 3,
      })}
      {...props}
    >
      {title}
    </h2>
  )
}
