import { colorThresholds } from '@/root/shared-consts'

const { GREEN_PERCENT, YELLOW_PERCENT } = colorThresholds

export const getTextColorByThreshold = (value = 0, max = 0) => {
  if (max === 0) return 'text-base-normal-lighter'

  const percent = Math.round((value / max) * 100)

  return {
    'text-success-medium-lighter': percent >= GREEN_PERCENT,
    'text-adm-yellow-400': percent >= YELLOW_PERCENT && percent < GREEN_PERCENT,
    'text-adm-red-400': percent < YELLOW_PERCENT,
  }
}
