import React, { FC, HTMLAttributes } from 'react'
import { ProgressBar, getTextColorByThreshold } from '@/root/modules/shared/SelectDateTimePage'
import { AvailableCapacityData, MaxCapacityMap, CapacityTableColumns } from '@/root/shared-types'
import cn from 'classnames'
import { CancelledIndicator } from '../CancelledIndicator/CancelledIndicator'
import { DangerGoodsIcon } from '@/root/ui/shared/icons/DangerGoodsIcon'
import { NoTrucksIcon } from '@/root/ui/shared/icons/NoTrucksIcon'
import { AgreeIcon } from '@/root/ui/shared/icons/AgreeIcon'
import { AnchorIcon } from '@/root/ui/shared/icons/AnchorIcon'

interface CapacityTableRowProps extends HTMLAttributes<HTMLButtonElement> {
  isSelected: boolean
  data: AvailableCapacityData
  maxValues: MaxCapacityMap
  showColumns: CapacityTableColumns
  onChange: () => void
  warningIconsCountInRow: number
}

export const CapacityTableRow: FC<CapacityTableRowProps> = ({
  isSelected,
  data,
  maxValues,
  showColumns,
  onChange,
  warningIconsCountInRow,
  ...props
}) => {
  const {
    time,
    passengersCount,
    bicyclesCount,
    carsCount,
    hasTrucks,
    dangerousGoods,
    noHeavyTracks,
    cancelledSail,
    closed,
  } = data
  const { maxPassengers = 0, maxBicycles = 0, maxCars = 0 } = maxValues
  const { showPassengers, showBicycles, showCars, showTrucks } = showColumns

  const passengerColorClass = getTextColorByThreshold(passengersCount, maxPassengers)
  const bicycleColorClass = getTextColorByThreshold(bicyclesCount, maxBicycles)
  const carColorClass = getTextColorByThreshold(carsCount, maxCars)

  return (
    <button
      data-testid="ticket-card"
      aria-pressed={isSelected}
      {...props}
      className={cn(
        'flex h-12 w-full cursor-pointer items-center justify-between gap-1 text-lg font-bold uppercase leading-6 xs:text-xl sm:text-large',
        {
          'rounded-lg border border-secondary bg-secondary-lightest': isSelected,
          '!h-11 rounded-lg border bg-alert-lightest border-alert-base !cursor-not-allowed': cancelledSail,
          '!cursor-not-allowed': closed,
        }
      )}
      onClick={onChange}
      disabled={cancelledSail || closed}
    >
      <input type="radio" name="capacity-row" checked={isSelected} onChange={onChange} className="hidden" />

      <div className="xs:gap-1 flex sm:gap-2">
        <div
          data-testid="sail-time"
          className={cn('ms-2.5 flex w-[76px] pt-1 text-sm xxs:w-20 xxs:text-base sm:w-24 sm:text-lg', {
            'text-primary-base': !isSelected,
            'text-secondary': isSelected,
          })}
        >
          {time}
          {closed && <span className="text-alert-base ms-0.5">*</span>}
        </div>

        <div
          data-testid="special-icons-for-this-sail"
          className={cn('flex items-center justify-center gap-1', {
            'w-7': warningIconsCountInRow === 1,
            'w-14': warningIconsCountInRow === 2,
            'w-16 xxs:w-20': warningIconsCountInRow === 3,
          })}
        >
          {dangerousGoods && <DangerGoodsIcon />}
          {noHeavyTracks && <NoTrucksIcon />}
          {cancelledSail && <AnchorIcon />}
        </div>
      </div>

      {showPassengers && (
        <div data-testid="availability-bar" className="progress-bar-small">
          {cancelledSail ? (
            <CancelledIndicator />
          ) : (
            <>
              {passengersCount !== undefined && (
                <div className={cn('flex flex-col items-center', passengerColorClass)}>
                  <div>
                    {passengersCount}
                    {passengersCount === 0 && <span>*</span>}
                  </div>
                  <ProgressBar value={passengersCount} max={maxPassengers} />
                </div>
              )}
            </>
          )}
        </div>
      )}

      {showBicycles && (
        <div data-testid="availability-bar" className="progress-bar-small">
          {cancelledSail ? (
            <CancelledIndicator />
          ) : (
            <>
              {bicyclesCount !== undefined && (
                <div className={cn('flex flex-col items-center', bicycleColorClass)}>
                  <div>
                    {bicyclesCount}
                    {bicyclesCount === 0 && <span>*</span>}
                  </div>
                  <ProgressBar value={bicyclesCount} max={maxBicycles} />
                </div>
              )}
            </>
          )}
        </div>
      )}

      {showCars && (
        <div data-testid="availability-bar" className={cn(showTrucks ? 'progress-bar-large' : 'progress-bar-small')}>
          {cancelledSail ? (
            <CancelledIndicator />
          ) : (
            <>
              {carsCount !== undefined && (
                <div className={cn('flex flex-col items-center', carColorClass)}>
                  {!hasTrucks && (
                    <div>
                      {carsCount}
                      {carsCount === 0 && <span>*</span>}
                    </div>
                  )}
                  <ProgressBar value={carsCount} max={maxCars} hasTruck={hasTrucks} />
                </div>
              )}
            </>
          )}
        </div>
      )}

      <div className="xxs:me-2 xxs:w-6 xs:me-4 me-1 flex w-5 justify-end md:w-24 lg:w-6 xl:w-24">
        {isSelected && <AgreeIcon />}
      </div>
    </button>
  )
}
